import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/Layout";
import { CenterLayout } from "../components/styled";

const TermsOfUse = () => {
    const { site } = useStaticQuery(graphql`
        {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);
    return (
        <Layout>
            <CenterLayout>
                <Copied
                    title={site.siteMetadata.title || "big boy"}
                    host="http://localhost:8000/"
                />
            </CenterLayout>
        </Layout>
    );
};

export default TermsOfUse;

const Copied = ({ title, host }) => {
    return (
        <div>
            <h1>Terms of Service</h1>

            <p>Last updated: July 10, 2020</p>

            <p>When we say “we”, “our”, or “us” in this document, we are referring to {title}.</p>

            <p>We may update these Terms of Service in the future.</p>

            <p>
                When you access our content, now or in the future, you are agreeing to the latest
                Terms of Service. That's true for any of our existing and future content. There may
                be times where we do not exercise or enforce any right or provision of the Terms of
                Service; in doing so, we are not waiving that right or provision. These terms do
                contain a limitation of our liability.
            </p>

            <h3>Copyright and Content Ownership</h3>

            <p>
                The names, look, and feel of the Services are copyright© to the Company. All rights
                reserved. You may not duplicate, copy, or reuse any portion of the HTML, CSS,
                JavaScript, or visual design elements without express written permission from the
                Company. You must request permission to use the Company's logo or any Service logos
                for promotional purposes. Please contact us to request to use logos. We reserve the
                right to rescind this permission if you violate these Terms of Service. You agree
                not to reproduce, duplicate, copy, sell, resell or exploit any portion of the
                Services, use of the Services, or access to the Services without the express written
                permission by the Company. You must not modify another website so as to falsely
                imply that it is associated with the Services or the Company.
            </p>

            <h3>Liability</h3>

            <p>We mention liability throughout these Terms but to put it all in one section:</p>

            <p>
                You expressly understand and agree that {title} shall not be liable, in law or in
                equity, to you or to any third party for any direct, indirect, incidental, lost
                profits, special, consequential, punitive or exemplary damages, including, but not
                limited to, damages for loss of profits, goodwill, use, data or other intangible
                losses (even if the Company has been advised of the possibility of such damages),
                resulting from: (i) the use or the inability to use the Services; (ii) the cost of
                procurement of substitute goods and services resulting from any goods, data,
                information or services purchased or obtained or messages received or transactions
                entered into through or from the Services; (iii) unauthorized access to or
                alteration of your transmissions or data; (iv) statements or conduct of any third
                party on the service; (v) or any other matter relating to this Terms of Service or
                the Services, whether as a breach of contract, tort (including negligence whether
                active or passive), or any other theory of liability.
            </p>

            <p>
                In other words: choosing to use our Services does mean you are making a bet on us.
                If the bet does not work out, that's on you, not us. We do our darnedest to be as
                safe a bet as possible through careful management of the business; investments in
                security, infrastructure, and talent; and in general giving a damn. If you choose to
                use our Services, thank you for betting on us.
            </p>

            <p>If you have a question about any of the Terms of Service, please contact us </p>
        </div>
    );
};
